.list {
    max-height: 200px;
    @media (min-width: 1024px) {
      max-height: 700px;
    }
  }

.listSmaller {
  max-height: 200px;
  @media (min-width: 1024px) {
    max-height: 300px;
  }
}